import { graphql, useStaticQuery } from "gatsby"

const useInformation = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        posts(first: 1) {
          nodes {
            date
            title
            content
          }
        }
      }
    }
  `)

  return data.wordPress.posts.nodes[0]
}

export default useInformation
