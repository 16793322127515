import styled from "@emotion/styled"
import Block from "./common/block"
import { Link } from "gatsby"
import { toLocalDate } from "../../helpers"
import { Scrollbars } from "rc-scrollbars"
import React, { useEffect, useState } from "react"
import NyiconOne from "../../assets/ny-icons/icon-1.svg"
import NyiconTwo from "../../assets/ny-icons/icon-2.svg"
import NyiconThree from "../../assets/ny-icons/icon-3.svg"
import NyiconFour from "../../assets/ny-icons/icon-4.svg"

const ContentWrapper = styled(Block)`
  max-width: 280px;
  flex-shrink: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin-right: -15px;
  }

  &:last-of-type {
    margin-top: 20px;
    flex-grow: 1;
  }
  ${props => props.theme.tablet} {
    max-width: 100%;
    &:last-of-type {
      margin-top: 0px;
    }
  }
`

const LastNews = styled.div`
  padding-right: 1rem;
  font-size: 0.9rem;
  li {
    font-size: 0.9rem !important;
  }
  img {
    display: none;
  }
  a {
    display: none;
  }
`

const AsideLink = styled(Link)`
  color: white;
  font-size: 1.3rem;
  font-style: normal;
  letter-spacing: 0em;
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 15px;
  cursor: pointer;
`

const NewsLink = styled(Link)`
  display: inline-block;
  margin-top: 1rem;
  border-bottom: 1px solid white;
  color: white;
`

const AsideWrapper = styled.aside`
  display: flex;
  ${props => props.theme.tablet} {
    flex-direction: column;
    width: 100%;
  }
  h3 {
    color: white;
  }
`
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const AboutWrapper = styled.p`
  p {
    margin-bottom: 25px;
  }
`

const NewsWrapper = styled.div`
  p {
    margin-bottom: 0.7rem;
  }
`

const ScrollableBlock = styled.div`
  height: 250px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-radius: 5px;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 15px;
  h2 {
    margin-bottom: 0px;
    line-height: 1;
  }

  a {
    line-height: 1;
    margin-bottom: 0px;
  }

  svg {
    height: 25px;
    margin: 0;
  }
`

const CustomScrollBars = ({ children, height }) => {
  const myOwnStyles = {
    backgroundColor: "lightgray",
    width: "4px",
    right: "0",
    top: "2px",
    bottom: "2px",
    borderRadius: "3px",
  }
  const thumbStyles = { backgroundColor: "gray" }
  const horizontalStyles = {
    display: "none",
  }

  return (
    <Scrollbars
      autoHide={false}
      autoHeight
      autoHeightMin={0}
      autoHeightMax={height}
      disableDefaultStyles
      renderTrackHorizontal={({ style, ...props }) => (
        <div {...props} style={{ ...style, ...horizontalStyles }} />
      )}
      renderThumbHorizontal={({ style, ...props }) => (
        <div {...props} style={{ ...style, ...horizontalStyles }} />
      )}
      renderTrackVertical={({ style, ...props }) => (
        <div {...props} style={{ ...style, ...myOwnStyles }} />
      )}
      renderThumbVertical={({ style, ...props }) => (
        <div {...props} style={{ ...style, ...thumbStyles }} />
      )}
    >
      {children}
    </Scrollbars>
  )
}

export default function Aside({ aboutData, lastNews }) {
  const [news, setNews] = useState(null)
  const [about, setAbout] = useState(null)

  const AboutBlockServer = () => {
    return (
      <ScrollableBlock>
        <AboutWrapper dangerouslySetInnerHTML={{ __html: aboutData }} />
      </ScrollableBlock>
    )
  }

  const NewsBlockServer = () => {
    return (
      <ScrollableBlock>
        <span>{toLocalDate(lastNews.date)}</span>
        <h3>{lastNews.title}</h3>
        <LastNews dangerouslySetInnerHTML={{ __html: lastNews.content }} />
        <NewsLink to="/news/page/1">Ещё новости</NewsLink>
      </ScrollableBlock>
    )
  }

  useEffect(() => {
    const AboutBlock = () => {
      return (
        <CustomScrollBars height={210}>
          <AboutWrapper dangerouslySetInnerHTML={{ __html: aboutData }} />
        </CustomScrollBars>
      )
    }
    setAbout(AboutBlock)
  }, [aboutData])

  useEffect(() => {
    const NewsBlock = () => {
      return (
        <CustomScrollBars height={260}>
          <span>{toLocalDate(lastNews.date)}</span>
          <h3>{lastNews.title}</h3>
          <LastNews dangerouslySetInnerHTML={{ __html: lastNews.content }} />
          <NewsLink to="/news/page/1">Ещё новости</NewsLink>
        </CustomScrollBars>
      )
    }
    setNews(NewsBlock)
  }, [lastNews])

  return (
    <AsideWrapper>
      <ColumnWrapper>
        <ContentWrapper>
          <HeaderWrapper>
            <h2>О нас</h2>

          </HeaderWrapper>
          {about ? about : <AboutBlockServer />}
        </ContentWrapper>
        <ContentWrapper>
          <HeaderWrapper>
            <AsideLink to="/news/page/1">Новости</AsideLink>

          </HeaderWrapper>
          <NewsWrapper>{news ? news : <NewsBlockServer />}</NewsWrapper>
        </ContentWrapper>
      </ColumnWrapper>
      <ColumnWrapper>
        <ContentWrapper>
          <HeaderWrapper>
            <AsideLink to="/portfolio/">Портфолио</AsideLink>

          </HeaderWrapper>

          <ul>
            <li>
              <Link to="/portfolio/general-plan">
                Генеральный план города Перми
              </Link>
            </li>
            <li>
              <Link to="/portfolio/strategic-plan">
                Стратегический Мастер-план Перми
              </Link>
            </li>
            <li>
              <Link to="/portfolio/illumination-plan">
                Мастер-план освещения города Перми
              </Link>
            </li>
            <li>
              <Link
                to={`/portfolio#${encodeURIComponent(
                  "Концепции преобразования городских пространств"
                )}`}
              >
                Концепции преобразования городских пространств
              </Link>
            </li>
            <li>
              <Link
                to={`/portfolio#${encodeURIComponent(
                  "Документация по планировке территории"
                )}`}
              >
                Документация по планировке территории
              </Link>
            </li>
          </ul>
        </ContentWrapper>
        <ContentWrapper>
          <HeaderWrapper>
            <AsideLink to="/information/">Информация</AsideLink>
            
          </HeaderWrapper>
          <ul>
            <li>
              <Link to={`/information#${encodeURIComponent("Об Учреждении")}`}>
                Об Учреждении
              </Link>
            </li>
            <li>
              <Link
                to={`/information#${encodeURIComponent(
                  "Учредительные документы"
                )}`}
              >
                Учредительные документы
              </Link>
            </li>
            <li>
              <Link
                to={`/information#${encodeURIComponent(
                  "Противодействие коррупции"
                )}`}
              >
                Противодействие коррупции
              </Link>
            </li>
            <li>
              <Link to={`/information#${encodeURIComponent("Отчеты")}`}>
                Отчеты
              </Link>
            </li>
          </ul>
        </ContentWrapper>
      </ColumnWrapper>
    </AsideWrapper>
  )
}
