import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, EffectCube, Autoplay } from "swiper"
import styled from "@emotion/styled"

import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const BridgeTest = () => {
  const {
    allFile: { edges: allEdges },
  } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(jpeg)/" }
            relativeDirectory: { eq: "index" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `
  )
  const pluginImages = allEdges.map(edge => getImage(edge.node))
  const slides = pluginImages.map(pluginImage => (
    <SwiperSlide key={pluginImage.images.fallback.src}>
      <BgImage
        image={pluginImage}
        style={{ width: "100%", height: "100%" }}
        loading="eager"
      />
    </SwiperSlide>
  ))

  return (
    <SwiperStyled
      spaceBetween={20}
      slidesPerView={1}
      pagination
      autoplay={{ delay: 7000 }}
    >
      {slides}
    </SwiperStyled>
  )
}

const SwiperStyled = styled(Swiper)`
  height: 100vh;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: black;
`

const SwiperWrapper = styled.div`
  z-index: -1;
  .swiper-pagination {
    bottom: 1.8rem;
  }
  .swiper-pagination-bullet {
    width: 0.7em;
    height: 0.7em;
  }
  .swiper-pagination-bullet {
  }
  ${props => props.theme.tablet} {
    display: none;
  }
`

SwiperCore.use([Pagination, EffectCube, Autoplay])

export default function Slider() {
  return (
    <SwiperWrapper>
      <BridgeTest />
    </SwiperWrapper>
  )
}
