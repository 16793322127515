import React from "react"
import Slider from "../components/indexPage/sliderBackground"
import Contacts from "../components/indexPage/contacts"
import Aside from "../components/indexPage/aside"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import useAbout from "../hooks/use-about"
import useLastNews from "../hooks/use-last-news"

const MainPageWrapper = styled.div`
  display: flex;
  ${props => props.theme.tablet} {
    flex-direction: column;
    padding-top: 0.9rem;
  }
  max-width: 95vw;
  margin: 0 auto;
  width: 1780px;
  flex-grow: 1;
  justify-content: space-between;
  padding-top: 38px;
  align-items: flex-start;
`

export default function Home({ data }) {
  const aboutData = useAbout()
  const lastNews = useLastNews()
  return (
    <div>
      <Slider />
      <Layout>
        <MainPageWrapper>
          <Aside aboutData={aboutData.content} lastNews={lastNews} />
          <Contacts />
        </MainPageWrapper>
      </Layout>
    </div>
  )
}
