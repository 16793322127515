import React from "react"
import styled from "@emotion/styled"
import Block from "./common/block"
import { Link } from "gatsby"
import useContacts from "../../hooks/use-contacts"
import NyiconFive from "../../assets/ny-icons/icon-5.svg"

const ContactsWrapper = styled(Block)`
  align-self: flex-end;
  margin-bottom: 65px;
  max-width: 315px;
  a {
    color: white;
  }
  ${props => props.theme.tablet} {
    max-width: 100%;
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  color: white;
  font-size: 1.3rem;
  font-style: normal;
  letter-spacing: 0em;
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 15px;
  cursor: pointer;
  ${props => props.theme.mobile} {
    margin-bottom: 0;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 15px;
  h2 {
    margin-bottom: 0px;
    line-height: 1;
  }

  a {
    line-height: 1;
    margin-bottom: 0px;
  }

  svg {
    height: 25px;
    margin: 0;
  }
`

const ItemWrapper = styled.span`
  display: block;
  margin-bottom: 0.3rem;
`

export default function Contacts() {
  const contactsData = useContacts()
  return (
    <ContactsWrapper>
      <HeaderWrapper>
        <StyledLink to="/contacts">Контакты</StyledLink>
      </HeaderWrapper>
      <p>
        <ItemWrapper>
          Юридический адрес: <br />
          <span
            dangerouslySetInnerHTML={{ __html: contactsData.legalAddress }}
          />
        </ItemWrapper>
        <ItemWrapper>
          Адреса офисов: <br />
          <span
            dangerouslySetInnerHTML={{ __html: contactsData.mailingAddress }}
          />
        </ItemWrapper>
        <ItemWrapper>Телефон: {contactsData.phoneNumber}</ItemWrapper>
        <ItemWrapper>
          E-Mail:{" "}
          <a href={`mailto:${contactsData.eMail}`}>{contactsData.eMail}</a>
        </ItemWrapper>
        <ItemWrapper>Приемные часы: {contactsData.businessHours}</ItemWrapper>
      </p>
    </ContactsWrapper>
  )
}
