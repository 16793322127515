import React from "react"
import styled from "@emotion/styled"

const BlockWrapper = styled.div`
  z-index: 800;
  background-color: black;
  color: white;
  background: ${props => props.theme.colors.blockBackground};
  border-radius: 5px;
  padding: 15px;
  font-size: 0.9rem;
  ${props => props.theme.tablet} {
    width: 100%;
    margin-bottom: 1rem;
    background: ${props => props.theme.colors.footer};
  }
  h2 {
    color: white;
    font-size: 1.3rem;
    font-style: normal;
    letter-spacing: 0em;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0px;
    margin: 0px;
    li {
      a {
        color: white;
      }
      &:first-of-type {
        padding-top: 0px;
      }
      font-size: 1rem;
      line-height: 1.3rem;
      border-bottom: 1px solid white;
      padding: 8px 0;
      &:last-of-type {
        border: none;
        padding-bottom: 0px;
      }
    }
  }
`

export default function Block(props) {
  return <BlockWrapper {...props}>{props.children}</BlockWrapper>
}
